
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@styles/globals.scss';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import React, { ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
export type NextPageWithLayout<P = Record<string, unknown>, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
};
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
};
function App({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
    if (typeof window === 'undefined')
        React.useLayoutEffect = React.useEffect;
    const getLayout = Component.getLayout ?? (page => page);
    const [queryClient] = React.useState(() => new QueryClient());
    return <QueryClientProvider client={queryClient}>{getLayout(<Component {...pageProps}/>)}</QueryClientProvider>;
}
const __Next_Translate__Page__18dc19aa372__ = App;

    export default __appWithI18n(__Next_Translate__Page__18dc19aa372__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  